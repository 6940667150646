export default defineComponent({
  props: {
    positiveImpact: {
      type: Boolean,
      default: true
    },
    relevancy: {
      type: Object,
      default: function _default() {
        occurance_ratio: 0;
      }
    }
  },
  setup: function setup() {}
});